import React from 'react';
import { Section } from '@/modules/entenda-mais/layout';
import * as S from './styles';

const NewTitle = (): JSX.Element => {
  const sectionDetail = {
    title: 'ENCERRAMENTO CARTOLA EXPRESS',
    index: 0,
  };

  return (
    <Section header={sectionDetail.title} index={sectionDetail.index}>
      <span>
        Assim como atletas encerram sua carreira depois de muitas vitórias, o
        Cartola Express também vai pendurar as chuteiras.
      </span>
      <br />
      <span>
        Após ter cumprido uma bela trajetória, por transparência e para oferecer
        a melhor experiência a seus usuários, o fantasy game de disputas rápidas
        da Globo anuncia que vai encerrar as suas atividades no dia 18 de
        dezembro.
      </span>
      <br />
      <span>
        <S.link
          href="https://ajuda.globo/cartola-express/faq/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Clique aqui
        </S.link>{' '}
        e confira todas as orientações sobre o encerramento, incluindo questões
        de saques e declaração de imposto de renda em 2025.
      </span>
      <br />
      <span>
        Para orientação personalizada, entre em contato com o Atendimento
        <S.link
          href="https://forms.bluelab.com.br/form/globo?alias=cartola_express"
          rel="noopener noreferrer"
          target="_blank"
        >
          {' '}
          clicando aqui.
        </S.link>
      </span>
    </Section>
  );
};

export default NewTitle;
