import React from 'react';
import * as S from './styles';
import { Section } from '@/modules/encerramento/layout';
import { Accordion } from '../dataDisplay/accordion';
import { iconChat } from '../../assets/icons';

const EncerramentoWebComponentDuvidas = (): JSX.Element => {
  const sectionDetail = {
    title: 'Dúvidas frequentes',
    index: 0,
  };

  return (
    <S.DuvidasContainer>
      <Section header={sectionDetail.title} index={sectionDetail.index}>
        <Accordion header="Preciso validar minha identidade para realizar saques?">
          <span>
            Para realizar saques no Cartola Express, você precisará validar sua
            identidade caso tenha atingido o limite máximo do total de saques de
            R$1.900. Para isso, precisará enviar uma foto do seu RG, RNE ou CNH
            na plataforma. Você pode verificar o status de validação de
            documentos na carteira, na aba SAQUE. Após a validação e aprovação,
            você poderá sacar os valores disponíveis na sua carteira. Caso você
            ainda não tenha acumulado R$ 1.900 em saques, não precisará validar
            seus documentos e poderá sacar o valor restante de sua carteira.
          </span>
        </Accordion>
        <Accordion header="Como poderei declarar meu Imposto de Renda em 2025?">
          <span>
            Não precisa se preocupar! Mesmo com o fim do Cartola Express, iremos
            enviar mais detalhes sobre o Informe de Rendimentos em 2025 para o
            seu e-mail.
          </span>
        </Accordion>
        <Accordion header="Ainda consigo acessar meu histórico de partidas e transações?">
          <span>
            Sim. Todas as informações de depósitos, inscrições e premiações
            estarão disponíveis no extrato transacional da sua carteira. Basta
            filtrar os dados no período desejado.
          </span>
        </Accordion>
        <Accordion header="O que acontecerá com os dados da minha conta após o encerramento?">
          <span>
            Sua conta no Cartola Express será desativada após o encerramento do
            jogo e o saque que você deverá fazer de todos os valores que você
            tiver na sua carteira. Porém, o e-mail Globo associado continuará
            ativo para acessar outras assinaturas vinculadas ao mesmo login,
            como Globoplay, Cartola e outros serviços, caso você tenha.
          </span>
        </Accordion>
        <S.TextoContainer>
          <S.ImagemIcone src={iconChat} alt="Ícone do Entenda mais" />
          <S.TextoPrincipal>
            Precisa de ajuda? Estamos aqui! Fale com os nossos atendentes pela{' '}
            <S.TextoDestaque
              href="https://forms.bluelab.com.br/form/globo?alias=cartola_express"
              target="_blank"
              rel="noopener noreferrer"
            >
              Central de Atendimento
            </S.TextoDestaque>
          </S.TextoPrincipal>
        </S.TextoContainer>
      </Section>
    </S.DuvidasContainer>
  );
};

export default EncerramentoWebComponentDuvidas;
