import React, { Suspense, useEffect, useState } from 'react';
import { HeaderTop } from './components/header';
import EncerramentoWebComponentHowToPlay from './components/how-to-play';
import './encerramento.style.scss';
import EncerramentoWebComponentDuvidas from './components/duvidas';
import { Footer } from './components/footer';
import HeroBanner from './components/herobanner/herobanner';
import imageContinueMostrando from '@/modules/encerramento/assets/img/banner-continue-mostrando.png';
import imageContinueMostrandoMobile from '@/modules/encerramento/assets/img/banner-continue-mostrando-mobile.png';
import imageChuteira from '@/modules/encerramento/assets/img/banner-chuteira.png';
import imageUltimasOfertas from '@/modules/encerramento/assets/img/banner-ultimas-ofertas.png';
import imageUltimasOfertasMobile from '@/modules/encerramento/assets/img/banner-ultimas-ofertas-mobile.png';
import DetailCard from './components/detailCard/detailCard';

import imagePrincipal from '@/modules/encerramento/assets/img/banner-prinicpal-pos18.png';
import imagePrincipalMobile from '@/modules/encerramento/assets/img/banner-principal-mobile-pos18.png';

interface Props {
  callFanduel: () => void;
}

const EncerramentoWebComponent = ({ callFanduel }: Props): JSX.Element => {
  const destaqueRef = React.useRef<HTMLDivElement>(null);
  const saqueRef = React.useRef<HTMLDivElement>(null);
  const duvidasRef = React.useRef<HTMLDivElement>(null);

  const handleCallBackRef = (event: React.MouseEvent, refName: string) => {
    event.preventDefault();

    const destaqueOffsetAdjustment = 100;
    const saqueOffsetAdjustment = 25;
    const duvidasOffsetAdjustment = 35;

    let offsetTop: number | null = null;

    switch (refName) {
      case 'destaque':
        if (destaqueRef.current) {
          offsetTop = destaqueRef.current.offsetTop - destaqueOffsetAdjustment;
        }
        break;
      case 'saque':
        if (saqueRef.current) {
          offsetTop = saqueRef.current.offsetTop - saqueOffsetAdjustment;
        }
        break;
      case 'duvidas':
        if (duvidasRef.current) {
          offsetTop = duvidasRef.current.offsetTop - duvidasOffsetAdjustment;
        }
        break;
      default:
        break;
    }

    if (offsetTop !== null) {
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const [imagemUltimasOfertas, setImagemUltimasOfertas] =
    useState(imageUltimasOfertas);
  const [imagemContinueMostrando, setImagemContinueMostrando] =
    useState(imageUltimasOfertas);

  useEffect(() => {
    const ajustarImagensPorTamanhoTela = () => {
      if (window.innerWidth <= 1248) {
        setImagemUltimasOfertas(imageUltimasOfertasMobile);
        setImagemContinueMostrando(imageContinueMostrandoMobile);
      } else {
        setImagemUltimasOfertas(imageUltimasOfertas);
        setImagemContinueMostrando(imageContinueMostrando);
      }
    };

    ajustarImagensPorTamanhoTela();

    window.addEventListener('resize', ajustarImagensPorTamanhoTela);

    return () =>
      window.removeEventListener('resize', ajustarImagensPorTamanhoTela);
  }, []);

  return (
    <Suspense fallback="Carregando...">
      <div className="container">
        <HeaderTop callBackRef={handleCallBackRef} callFanduel={callFanduel} />
        <div
          ref={destaqueRef}
          onClick={(event) => handleCallBackRef(event, 'saque')}
        >
          <img
            src={imagePrincipal}
            alt="banner principal"
            className="container__image"
          />
          <img
            src={imagePrincipalMobile}
            alt="banner principal"
            className="container__image-mobile"
          />
        </div>
        <DetailCard
          imageSrc={imageChuteira}
          title="Assim como atletas encerram sua carreira depois de muitas vitórias, o Cartola Express também vai pendurar as chuteiras."
          description="Após ter cumprido uma bela trajetória, por transparência e para oferecer a melhor experiência a seus usuários, o fantasy game de disputas rápidas da Globo anuncia que vai encerrar as suas atividades no dia 18 de dezembro."
        />
        <HeroBanner
          title="Depois de uma bela jornada ao lado dos fãs de fantasy games, o Cartola Express prepara grandes emoções para esta reta final! "
          imageUrl={imagemUltimasOfertas}
          link="https://cartolaexpress.globo.com/lobby"
        />
        <div className="container__regras">
          <div className="container__regras__content" ref={saqueRef}>
            <h2 className="container__regras__content__header">
              Para aqueles que ainda têm valores na carteira do Cartola Express,
              o processo de{' '}
              <span className="container__regras__content__header__Highlight">
                saque
              </span>{' '}
              é simples e rápido
            </h2>
            <EncerramentoWebComponentHowToPlay />
          </div>
        </div>
        <div ref={duvidasRef}>
          <EncerramentoWebComponentDuvidas />
        </div>
        <HeroBanner
          imageUrl={imagemContinueMostrando}
          link="https://cartola.globo.com/palpites"
        />
        <Footer isChange={false} />
      </div>
    </Suspense>
  );
};

export default EncerramentoWebComponent;
